<template>
  <ValidationObserver ref="observer">
    <form class="StatusPageLanguageForm"
          @submit.prevent="submit">

      <StatusPageAccordion
        title="Button Get Updates"
        class="pt-3"
        @open-accordion="setAccordion(1)"
        :isOpen="activeAccordion === 1"
      >
        <base-textbox
          label="Get Updates"
          ref="button_get_updates"
          placeholder="Get Updates"
          rules="max:255"
          v-model="statusPageLanguage.button_get_updates"
        />

        <base-textbox
          label="Subscribe for updates"
          placeholder="Subscribe for updates"
          rules="max:255"
          v-model="statusPageLanguage.text_popup_get_updates_title"
        />

        <base-textbox
          label="Subscribe to receive emails every time publishes an update"
          placeholder="Subscribe to receive emails every time publishes an update"
          rules="max:255"
          v-model="statusPageLanguage.text_popup_get_updates_text"
        />

        <base-textbox
          label="Submit"
          placeholder="Submit"
          rules="max:255"
          v-model="statusPageLanguage.button_popup_get_updates"
        />
      </StatusPageAccordion>
      <hr class="accordion-row">

      <StatusPageAccordion
        title="System Status"
        @open-accordion="setAccordion(2)"
        :isOpen="activeAccordion === 2"
      >

        <base-textbox
          label="All Systems Operational"
          placeholder="All Systems Operational"
          rules="max:255"
          v-model="statusPageLanguage.text_monitors_status_all_up"
        />

        <base-textbox
          label="Major Systems Outage"
          placeholder="Major Systems Outage"
          rules="max:255"
          v-model="statusPageLanguage.text_monitors_status_all_down"
        />

        <base-textbox
          label="Partial Systems Outage"
          placeholder="Partial Systems Outage"
          rules="max:255"
          v-model="statusPageLanguage.text_monitors_status_some_down"
        />
      </StatusPageAccordion>
      <hr class="accordion-row">

      <StatusPageAccordion
        title="Uptime"
        @open-accordion="setAccordion(3)"
        :isOpen="activeAccordion === 3"
      >
        <base-textbox
          label="Uptime"
          placeholder="Uptime"
          rules="max:255"
          v-model="statusPageLanguage.text_uptime"
        />
        <base-textbox
          label="Last"
          placeholder="Last"
          rules="max:255"
          v-model="statusPageLanguage.text_uptime_last"
        />
        <base-textbox
          label="Days"
          placeholder="Days"
          rules="max:255"
          v-model="statusPageLanguage.text_uptime_days"
        />
        <base-textbox
          label="Monitor Uptime"
          placeholder="Monitor Uptime"
          rules="max:255"
          v-model="statusPageLanguage.text_monitor_uptime"
        />
      </StatusPageAccordion>
      <hr class="accordion-row">

      <StatusPageAccordion
        title="Incidents"
        @open-accordion="setAccordion(4)"
        :isOpen="activeAccordion === 4"
      >
        <base-textbox
          label="Breakdown of uptime by locations on"
          placeholder="Breakdown of uptime by locations on"
          rules="max:255"
          v-model="statusPageLanguage.text_popup_incident_text"
        />
        <base-textbox
          label="Location"
          placeholder="Location"
          rules="max:255"
          v-model="statusPageLanguage.text_popup_incident_location"
        />
        <base-textbox
          label="Uptime"
          placeholder="Uptime"
          rules="max:255"
          v-model="statusPageLanguage.text_popup_incident_uptime"
        />
        <base-textbox
          label="Downtime"
          placeholder="Downtime"
          rules="max:255"
          v-model="statusPageLanguage.text_popup_incident_downtime"
        />
        <base-textbox
          label="Latency"
          placeholder="Latency"
          rules="max:255"
          v-model="statusPageLanguage.text_popup_incident_latency"
        />
        <base-textbox
          label="Status: Exploring"
          placeholder="Exploring"
          rules="max:255"
          v-model="statusPageLanguage.text_incident_status_exploring"
        />
        <base-textbox
          label="Status: Identified"
          placeholder="Identified"
          rules="max:255"
          v-model="statusPageLanguage.text_incident_status_identified"
        />
        <base-textbox
          label="Status: Update"
          placeholder="Update"
          rules="max:255"
          v-model="statusPageLanguage.text_incident_status_update"
        />
        <base-textbox
          label="Status: Resolved"
          placeholder="Resolved"
          rules="max:255"
          v-model="statusPageLanguage.text_incident_status_resolved"
        />
        <base-textbox
          label="Recent Incidents"
          placeholder="Recent Incidents"
          rules="max:255"
          v-model="statusPageLanguage.text_recent_incidents"
        />

        <base-textbox
          label="Incidents History"
          placeholder="Incidents History"
          rules="max:255"
          v-model="statusPageLanguage.text_incidents_history"
        />

        <base-textbox
          label="No incidents reported."
          placeholder="No incidents reported."
          rules="max:255"
          v-model="statusPageLanguage.text_no_incidents_reported"
        />

        <base-textbox
          label="Show Incident History"
          placeholder="Show Incident History"
          rules="max:255"
          v-model="statusPageLanguage.text_show_incident_history"
        />

        <base-textbox
          label="Go Back"
          placeholder="Go Back"
          rules="max:255"
          v-model="statusPageLanguage.text_go_back"
        />

      </StatusPageAccordion>
      <hr class="accordion-row">
      <StatusPageAccordion title="Maintenances"
                           @open-accordion="setAccordion(5)"
                           :isOpen="activeAccordion === 5">

        <base-textbox
          label="Maintenance"
          placeholder="Maintenance"
          rules="max:255"
          v-model="statusPageLanguage.text_maintenance"
        />
        <base-textbox
          label="Maintenance history"
          placeholder="Maintenance history"
          rules="max:255"
          v-model="statusPageLanguage.text_maintenance_history"
        />
        <base-textbox
          label="No maintenance"
          placeholder="No maintenance."
          rules="max:255"
          v-model="statusPageLanguage.text_no_maintenance"
        />
        <base-textbox
          label="Upcoming"
          placeholder="Upcoming"
          rules="max:255"
          v-model="statusPageLanguage.text_upcoming"
        />
        <base-textbox
          label="Scheduled on"
          placeholder="Scheduled on"
          rules="max:255"
          v-model="statusPageLanguage.text_scheduled_on"
        />
        <base-textbox
          label="Maintenance in progress"
          placeholder="Maintenance in progress"
          rules="max:255"
          v-model="statusPageLanguage.text_maintenance_in_progress"
        />
        <base-textbox
          label="Started"
          placeholder="Started"
          rules="max:255"
          v-model="statusPageLanguage.text_maintenance_started"
        />

        <base-textbox
          label="Seconds ago"
          placeholder="Seconds ago"
          rules="max:255"
          v-model="statusPageLanguage.text_maintenance_started_seconds_ago"
        />
        <base-textbox
          label="Minutes ago"
          placeholder="Minutes ago"
          rules="max:255"
          v-model="statusPageLanguage.text_maintenance_started_minutes_ago"
        />
        <base-textbox
          label="Hours ago"
          placeholder="Hours ago"
          rules="max:255"
          v-model="statusPageLanguage.text_maintenance_started_hours_ago"
        />
        <base-textbox
          label="Days ago"
          placeholder="Days ago"
          rules="max:255"
          v-model="statusPageLanguage.text_maintenance_started_days_ago"
        />

        <base-textbox
          label="Ending in"
          placeholder="Ending in"
          rules="max:255"
          v-model="statusPageLanguage.text_maintenance_ending_in"
        />
        <base-textbox
          label="Ongoing Maintenance"
          placeholder="Ongoing Maintenance"
          rules="max:255"
          v-model="statusPageLanguage.text_ongoing_maintenance"
        />
      </StatusPageAccordion>

      <hr class="accordion-row">
      <StatusPageAccordion title="Password"
                           @open-accordion="setAccordion(6)"
                           :isOpen="activeAccordion === 6">

        <base-textbox
          label="Private page"
          placeholder="Private page"
          rules="max:255"
          v-model="statusPageLanguage.text_password_header"
        />

        <base-textbox
          label="Password"
          placeholder="Password"
          rules="max:255"
          v-model="statusPageLanguage.text_password_input_placeholder"
        />

        <base-textbox
          label="Submit"
          placeholder="Submit"
          rules="max:255"
          v-model="statusPageLanguage.text_password_button_submit"
        />

        <base-textbox
          label="Cancel"
          placeholder="Cancel"
          rules="max:255"
          v-model="statusPageLanguage.text_password_button_cancel"
        />

      </StatusPageAccordion>

      <hr class="accordion-row">

      <StatusPageAccordion title="Date and Time"
                           @open-accordion="setAccordion(7)"
                           :isOpen="activeAccordion === 7">
        <base-dropdown :options="locales" v-model="statusPageLanguage.date_and_time_language" label="Date and time language"/>
      </StatusPageAccordion>

      <div class="toolbar">

        <base-button
          type="submit"
          ref="submit"
          class="submit success"
          :loading="saving"
        >
          {{ submitLabel }}
        </base-button>

        <base-button
          type="button"
          class="is-outlined"
          ref="cancel"
          color="light"
          @click="goToStatusPagesList"
        >
          Cancel
        </base-button>
      </div>

      <div v-show="false">
        <hint-limit-exceeded ref="limitExceeded"/>
      </div>

      <div v-show="error" class="mt-4">
        <base-alert type="error">Something went wrong</base-alert>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import HintLimitExceeded from '@/components/Hints/HintStatusPagesLimitExceeded.vue'

import statusPagesApi from '@/api/statusPagesApi.js'

import tippy from 'tippy.js'
import BaseAlert from '@/components/Base/BaseAlert'
import StatusPageAccordion from '@/components/StatusPage/StatusPageAccordion'

export default {
  components: {
    BaseAlert,
    HintLimitExceeded,
    StatusPageAccordion
  },

  props: {
    mode: {
      required: false,
      type: String,
      default: 'update'
    },

    statusPage: {
      required: false,
      type: Object
    }
  },

  data () {
    return {
      locales: [],
      statusPageLanguage: {
        button_get_updates: '',
        text_popup_get_updates_title: '',
        text_popup_get_updates_text: '',
        button_popup_get_updates: '',
        text_monitors_status_all_up: '',
        text_monitors_status_all_down: '',
        text_monitors_status_some_down: '',
        text_uptime: '',
        text_uptime_last: '',
        text_uptime_days: '',
        text_monitor_uptime: '',
        text_popup_incident_text: '',
        text_popup_incident_location: '',
        text_popup_incident_uptime: '',
        text_popup_incident_downtime: '',
        text_popup_incident_latency: '',
        text_incident_status_exploring: '',
        text_incident_status_identified: '',
        text_incident_status_update: '',
        text_incident_status_resolved: '',
        text_recent_incidents: '',
        text_incidents_history: '',
        text_no_incidents_reported: '',
        text_show_incident_history: '',
        text_go_back: '',
        text_maintenance: '',
        text_maintenance_history: '',
        text_no_maintenance: '',
        text_upcoming: '',
        text_scheduled_on: '',
        text_maintenance_in_progress: '',
        text_maintenance_started: '',
        text_maintenance_ending_in: '',
        text_ongoing_maintenance: '',
        text_password_header: '',
        text_password_input_placeholder: '',
        text_password_button_submit: '',
        text_password_button_cancel: '',
        date_and_time_language: '',
        text_maintenance_started_seconds_ago: '',
        text_maintenance_started_minutes_ago: '',
        text_maintenance_started_hours_ago: '',
        text_maintenance_started_days_ago: ''
      },
      activeAccordion: 0,

      saving: false,
      limitExceededTippy: null,
      updateSuccessTippy: null,

      error: false
    }
  },

  async created () {
    if (this.statusPage.status_pages_language) {
      this.statusPageLanguage = JSON.parse(this.statusPage.status_pages_language.json_data)
    }

    import('dayjs/locale.json').then((res) => {
      this.locales = res.default.map(item => ({ type: item.key, title: item.name }))
    })
  },

  mounted () {
    this.$refs.button_get_updates.focus()

    this.updateButtonTippy = tippy(this.$refs.submit.$el, {
      content: 'Status page was successfully updated',
      arrow: false,
      placement: 'right',
      trigger: 'manual'
    })
  },

  methods: {
    async submit () {
      const valid = await this.$refs.observer.validate()
      if (!valid) {
        return false
      }

      this.saving = true

      try {
        this.error = false
        await statusPagesApi.storeOrUpdateLanguage(
          this.statusPageLanguage,
          this.statusPage.id
        )

        await this.refreshCurrentUser()

        this.showUpdateSuccess()
        this.$emit('submitSuccess')
      } catch (error) {
        console.log(error)
        this.error = true
      }

      // Update number of status pages in order to control onboarding screen

      this.saving = false
    },

    setAccordion (value) {
      if (this.activeAccordion === value) {
        this.activeAccordion = 0
      } else {
        this.activeAccordion = value
      }
    },

    showUpdateSuccess () {
      this.updateButtonTippy.show()

      setTimeout(() => {
        this.updateButtonTippy.hide()
      }, 2000)
    },

    goToStatusPagesList () {
      this.$router.push({
        name: 'statusPages.list'
      })
    },

    ...mapActions('authentication', [
      'refreshCurrentUser'
    ])
  },

  computed: {
    submitLabel () {
      if (this.mode === 'create') {
        return 'Save'
      } else {
        return 'Update'
      }
    },

    ...mapState('authentication', [
      'user'
    ])
  }
}
</script>

<style lang="scss" scoped>
.StatusPageLanguageForm {
  .header {
    font-size: 18px;
    margin-bottom: 10px;
  }

  ::v-deep {
    .c-accordion:first-of-type .c-accordion-header {
      padding-top: 10px !important;
    }
  }

  .logo-preview {
    display: flex;
    //flex-direction: column;
    align-items: center;
    margin-top: 18px;

    .logo {
      max-width: 110px;
      margin-right: 10px;
      border-radius: 5px;
      padding: 5px;
      background-color: #19221d;
      max-height: 46px;
    }
  }

  .accordion-row {
    border-top: 0;
    border-bottom: 1px solid #D6D9DA;
    margin: 0 !important;
  }

  .label {
    display: block;
    margin-bottom: 6px;
    color: map-get($colors, gray-1);
    font-size: 14px;
    font-weight: 600;
  }

  .pro-label {
    margin: 3px 0 0 8px;
    height: min-content;
    padding: 0px 6px;
    border: 1px solid #b1b1b1;
    border-radius: 43px;
    font-size: 10px;
    color: #fff;
    background-color: #b1b1b1;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.05em;
  }

  .checkbox-description {
    font-size: 12px;
    padding-left: 30px;
    margin-top: -17px;
    margin-bottom: 20px;
    opacity: 0.6;
    line-height: 17px;
  }

  .hint-icon {
    margin: 3px 0 0 5px;
  }

  .toolbar {
    position: sticky;
    bottom:0;
    background: rgba(255, 255, 255, 0.9);
    padding: 15px 0;
    backdrop-filter: blur(3px);
  }
}
</style>
